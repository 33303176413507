<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-danger ml-1">Job Portal</h2>
      </b-link>
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-row>
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              <b-alert show variant="success" class="p-2"
                >We have sent verification link to your email</b-alert
              >
            </b-card-title>
          </b-col>

          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-text class="mb-2">
              If you did not get email.Please enter resend mail
            </b-card-text>
            <!-- form -->
            <b-button class="mt-2" @click="resendMail" variant="primary" block>
              Resend Mail
            </b-button>
            <b v-if="message != ''" class="text-danger" style="margin-top: 3px;">{{ message }}</b>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BAlert,
} from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BAlert,
  },
  data() {
    return {
      message: "",
      email: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
    };
  },
  methods: {
    resendMail() {
      axios
        .post("/candidate/resend/candidate/email")
        .then((res) => {
          if (res.data) {
            this.s(res.data.message);
          }
        })
        .catch((e) => {
          this.e(e.response.data.error);
          this.message = e.response.data.error;
        });
    },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
